<template>
    <div>
        <div class="pt-container">
            <div class="pt-header">
                <div class="pt-title">
                    Histórico
                    <div id="help-icon" class="no-print">
                        <v-help-circle class="icon stroke" />
                    </div>
                    <b-tooltip target="help-icon" placement="bottom">
                        Registro de todas as contas faturadas com base nas pendências do gerenciador.
                    </b-tooltip>
                </div>
            </div>
        </div>
        <PendingHistoryResume />
        <PendingHistoryFilter @changeFilter="debounceFilter" />
        <PendingHistoryTable :loading="loading" :historyItems="historyItems" :showModal="showModal" />
        <div class="pagination-position">
            <b-pagination :value="page" :total-rows="count" :per-page="limit" first-number last-number size="sm" @input="onChangePage" />
        </div>

        <BillItemsModal :billId="billId" :patient="patient" :appointmentDate="appointmentDate"
            :checkedItems="checkedItems" @reload="reload" @clearItems="clearItems" @hidden="billId = null"
            @issueInvoice="issueInvoice" :creditPatientCategory="categorySelected" />
        <TissGuidesModalsContainer :patient="patient" :tissGuide="tissGuide" :susGuide="susGuide"
            :clinicHealthPlan="clinicHealthPlan" :getLastTissGuide="getLastTissGuide" :deleteTissGuide="deleteTissGuide"
            :clearTissGuide="clearTissGuide"
            :required_fields="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field"
            :getClinicHealthPlan="(invoicingTissSettingId) => { }" :setTissGuide="setTissGuide" :getGuide="getGuide"
            :checkedItems="checkedItems" v-if="clinicHealthPlan || tissGuide" />
            <NewInvoiceModal />
        <ResumeInvoiceModal />
    </div>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager';
import { handleGuideUpdate } from '@/utils/invoicingTissHelper';
import { debounce } from 'lodash';

export default {
    components: {
        PendingHistoryResume: () => import('@/modules/financial/components/PendingHistoryResume'),
        PendingHistoryTable: () => import('@/modules/financial/components/PendingHistoryTable'),
        PendingHistoryFilter: () => import('@/modules/financial/components/PendingHistoryFilter'),
        BillItemsModal: () => import('@/modules/financial/modals/BillItemsModal'),
        TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
        NewInvoiceModal: () => import('@/modules/nfe/modals/NewInvoiceModal'),
        ResumeInvoiceModal: () => import('@/modules/nfe/modals/ResumeInvoiceModal'),
        'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    },
    props: {
        patient: Object
    },
    data() {
        return {
            loading: false,
            historyItems: [],
            page: 1,
            count: 0,
            limit: 10,
            clinic: getCurrentClinic(),
            debounceFilter: debounce(this.handleChangeFilter, 500),
            billId: null,
            tissGuide: null,
            susGuide: null,
            clinicHealthPlan: null,
            appointmentDate: null,
            checkedItems: [],
            categorySelected: null,
            filter: {
                query: '',
                status: null,
                itemType: null,
                professional: null,
                period: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)],
                healthPlan: null
            }
        };
    },
    methods: {
        onChangePage(page) {
            this.page = page
            this.getHistoryItemsFromPatient(this.filter);
        },
        reload() {
            this.getHistoryItemsFromPatient(this.filter);
        },
        async getHistoryItemsFromPatient(filter) {
            try {
                this.filter = filter;
                this.loading = true;
                const { data } = await this.api.getHistoryItemsFromPatient(this.page, this.clinic.id, null, this.limit, filter?.query, filter?.status, filter?.itemType, filter?.professional, filter?.start, filter?.end, filter?.healthPlan);
                this.historyItems = data.data;
                this.count = data.total
                this.limit = data.per_page;
                this.page = data.current_page;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        handleChangeFilter(filter) {
            this.page = 1;
            this.getHistoryItemsFromPatient(filter);
        },
        async showModal(historyItem) {
            if (historyItem.type === 'bill') {
                this.billId = null;
                this.billId = historyItem.bill_id;
                this.$bvModal.show('bill-items-modal');
            } else if (historyItem.type === 'tissGuideItem') {
                await this.getGuide(historyItem.tiss_guide_id);
            } else if (historyItem.type === 'susGuideItem') {
                const isLoading = this.$loading.show();
                const { data } = await this.api.getSusGuideById(historyItem.guide_id);
                this.susGuide = data;
                if (historyItem.guide_type === "Guia BPA") {
                    this.$bvModal.show('bpa-sus-guide-modal');
                } else if (historyItem.guide_type === "Guia APAC") {
                    this.$bvModal.show('apac-sus-guide-modal');
                } else {
                    this.$bvModal.show('aih-sus-guide-modal');
                }

                isLoading.hide();
            }
        },
        async getGuide(tissGuideId) {
            if (typeof tissGuideId === 'object') tissGuideId = tissGuideId.id;
            const isLoading = this.$loading.show();
            try {
                const response = await this.api.getTissGuideById(tissGuideId);
                this.tissGuide = response.data;
                await this.getClinicHealthPlan(this.tissGuide.invoicing_tiss_setting_id);
                this.$bvModal.show(handleGuideUpdate(this.tissGuide.guide_type));
            } catch (error) {
                this.$toast.error(error.message);
            } finally {
                isLoading.hide();
            }
        },
        async getClinicHealthPlan(invoicingTissSettingId) {
            if (!this.clinic.id || !invoicingTissSettingId) return;
            try {
                const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
                    this.clinic.id,
                    invoicingTissSettingId
                );
                this.clinicHealthPlan = response.data;
                this.tissRequiredCustomField =
                    this.clinicHealthPlan.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field;
            } catch (error) {
                this.$toast.error(error.message);
            }
        },
        clearItems() {
            this.checkedItems = [];
        },
        issueInvoice() {
      this.$bvModal.show('new-invoice-modal')
    },

    }
};
</script>
<style lang="scss" scoped>
.pt-container {
    padding: 20px 10px;

    .pt-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .pt-title {
        display: inline-flex;
        align-items: center;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;

            .icon {
                height: 24px;
                width: 24px;
                margin-left: 5px;
                stroke: var(--neutral-500);
            }
        }
    }

    .pagination-position {
        padding: 0;
    }
}
</style>