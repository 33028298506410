import { render, staticRenderFns } from "./PendingHistory.vue?vue&type=template&id=4823377a&scoped=true"
import script from "./PendingHistory.vue?vue&type=script&lang=js"
export * from "./PendingHistory.vue?vue&type=script&lang=js"
import style0 from "./PendingHistory.vue?vue&type=style&index=0&id=4823377a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4823377a",
  null
  
)

export default component.exports